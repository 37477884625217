.container {
    max-width: inherit !important;
}

.alert {
    font-size: 2em;
    font-weight: bold;
}

.CCCKiosk {
    margin-top: 30px;
    font-size: 3em;
    text-align: center;
    width: 100%;
    position: relative;

    .screen {
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    h1 {
        font-size: 1.1em;
        font-weight: bold;

        .back {
            height: 80px;
            position: relative;
            top: -7px;
            left: -20px;
            width: 110px;
            font-size: 0.5em;
        }
    }

    button {
        font-size: 1.5em;
    }

    .not-connected {
        background-color: rgba(255, 0, 0, 0.3);
        border-radius: 25px;
        padding: 44px;

        p {
            line-height: 1.2;
        }
    }

    .idle-screen {
        .begin {
            height: 1060px;
            bottom: 40px;
        }
    }

    .setup-coupon-code-screen {
        .scan-qr {
            height: 420px;
            margin-bottom: 20px;
        }

        .manual-preview {
            font-size: 2em;
            font-weight: bold;
            background-color: #ddd;
            margin: 20px 0 35px 0;
            width: 100%;
            min-height: 200px;
            border: 2px solid black;
        }

        .manual-entry {
            height: 420px;
        }
    }

    .setup-strips-screen {
        .strip-entry {
            padding-top: 20px;

            h2 {
                font-size: 1.0em;
            }

            .touch-toggle {
                .toggle-option {
                    height: 100px;
                }
            }
        }

        .accept {
            margin-top: 30px;
            height: 190px;
        }

        .skip {
            margin-top: 20px;
            height: 120px;
        }
    }

    .photo-screen {
        .take-shot {
            margin-top: 10px;
            height: 250px;
            margin-bottom: 10px;
        }

        .try-again {
            height: 210px;
            margin-top: 10px;
        }

        .accept {
            height: 210px;
            margin-top: 10px;
        }
    }

    .confirm-screen {
        p {
            font-size: 0.9em;
        }

        .photo-preview {
            width: 500px;
        }

        .accept {
            height: 125px;

            &.no-photo {
                height: 260px;
            }
        }
    }

    .running-screen {
        .cancel {
            height: 140px;
            margin-top: 10px;
            margin-right: 20px;
        }

        .done {
            height: 140px;
            margin-top: 10px;
        }
    }

    .complete-screen {
        .aquisition-information {
            font-size: 0.8em;
        }

        img {
            width: 100%
        }

        .error {
            background-color: red;
            color: white;
            border-radius: 1.5rem;
            padding: 4px;

            p {
                font-size: 0.6em;
                line-height: 1.2em;
            }
        }

        .resubmit {
            margin-top: 20px;
        }
    }
}