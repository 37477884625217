@import '../../../styles/Variables.scss';

.lookup-table {
    .innactive {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .quick-link {
        width: 20px;
        display: inline-block;
        padding: 0 6px 0 6px;
        cursor: pointer;
    }

    h1 {
        font-weight: bold;

        small {
            font-size: 0.4em;
        }
    }
}

.table-container {
    overflow-x: auto;

    table {
        white-space: nowrap;
    }

    th {
        &.sortable {
            color: $PurafilBlue
        }
    }
}

.chart-contaienr {
    height: 400px;
    position: relative;
}