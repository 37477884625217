.lookup-table {
    .closed {
        background-color: rgba(136, 136, 136, 0.1);
    }

    .internalissue {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .externalissue {
        background-color: rgba(255, 0, 0, 0.1);
    }

    .inprogress {
        background-color: rgba(85, 255, 0, 0.1);
    }

    .quick-action {
        padding: 4px;
        cursor: pointer;
    }

    .priority1 {
        border-left: 8px solid yellow;
    }

    .priority2 {
        border-left: 8px solid green;
    }

    .priority3 {
        border-left: 8px solid red;
    }
}

.lab-entry-result {

    .needs-saving {
        border-color: red;
        background-color: rgba(255, 0, 0, 0.05);
    }

    .mini-form-row {

        input,
        textarea {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .attachment-container {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        display: flex;
        gap: 6px;
        padding: 6px;
        flex-wrap: wrap;

        .attachment {
            flex-basis: 120px;
            flex-grow: 0;
            flex-shrink: 0;
            height: 120px;
            width: 100px;
            border: 1px solid #707172;
            border-radius: 0.25rem;
            padding: 6px;
            position: relative;

            .fa-trash-can {
                position: absolute;
                top: 10px;
                right: 10px;
                color: red;
            }

            .fa-file-circle-check {
                color: rgba(100, 100, 100, 0.1);
                position: absolute;
                width: 90%;
                height: 90%;
            }

            .attachment-filename {
                position: absolute;
                bottom: 0;
                font-size: 0.8rem;
                color: #000;
            }
        }
    }
}