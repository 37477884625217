.touch-toggle {
    display: flex;
    flex-wrap: wrap;

    .toggle-option {
        height: 120px;
        flex: auto;
        margin: 4px;
        font-size: 1em;

        &.active {
            background-color: darkblue;
        }
    }
}