@font-face {
    font-family: 'muli';
    src: url("../assets/fonts/muli-regular-webfont.eot");
    src: url("../assets/fonts/muli-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/muli-regular-webfont.woff2") format("woff2"), url("../assets/fonts/muli-regular-webfont.woff") format("woff"), url("../assets/fonts/muli-regular-webfont.ttf") format("truetype"), url("../assets/fonts/muli-regular-webfont.svg#muliregular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'muli';
    src: url("../assets/fonts/muli-light-webfont.eot");
    src: url("../assets/fonts/muli-light-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/muli-light-webfont.woff2") format("woff2"), url("../assets/fonts/muli-light-webfont.woff") format("woff"), url("../assets/fonts/muli-light-webfont.ttf") format("truetype"), url("../assets/fonts/muli-light-webfont.svg#mulilight") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'source sans pro';
    src: url("../assets/fonts/sourcesanspro-semibold-webfont.eot");
    src: url("../assets/fonts/sourcesanspro-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/sourcesanspro-semibold-webfont.woff2") format("woff2"), url("../assets/fonts/sourcesanspro-semibold-webfont.woff") format("woff"), url("../assets/fonts/sourcesanspro-semibold-webfont.ttf") format("truetype"), url("../assets/fonts/sourcesanspro-semibold-webfont.svg#source_sans_prosemibold") format("svg");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'source sans pro';
    src: url("../assets/fonts/sourcesanspro-regular-webfont.eot");
    src: url("../assets/fonts/sourcesanspro-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/sourcesanspro-regular-webfont.woff2") format("woff2"), url("../assets/fonts/sourcesanspro-regular-webfont.woff") format("woff"), url("../assets/fonts/sourcesanspro-regular-webfont.ttf") format("truetype"), url("../assets/fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'source sans pro';
    src: url("../assets/fonts/sourcesanspro-light-webfont.eot");
    src: url("../assets/fonts/sourcesanspro-light-webfont.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/sourcesanspro-light-webfont.woff2") format("woff2"), url("../assets/fonts/sourcesanspro-light-webfont.woff") format("woff"), url("../assets/fonts/sourcesanspro-light-webfont.ttf") format("truetype"), url("../assets/fonts/sourcesanspro-light-webfont.svg#source_sans_prolight") format("svg");
    font-weight: 300;
    font-style: normal;
}