@import '../../styles/Variables.scss';

.sortable-table {
    overflow-x: auto;

    table {
        white-space: nowrap;
    }

    th {
        &.sortable {
            color: $PurafilBlue
        }
    }
}