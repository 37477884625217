.react-datepicker-wrapper {
    width: inherit;
}

.card {
    &.editing {
        background-color: rgba(255, 100, 0, 0.1);
    }
}

.react-datepicker__input-container span,
.form-value {
    border-bottom: 1px solid black;

    &.editable {
        cursor: pointer;
    }

    &.static {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.no-aquisition {
    background-color: rgba(255, 0, 0, 0.1);
}

.aquisition {
    background-color: rgba(255, 255, 0, 0.1);
}

.aquisition-approved {
    background-color: rgba(0, 255, 0, 0.1);
}