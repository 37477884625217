.touch-keyboard {
    .key-container {
        display: flex;
        flex-wrap: wrap;

        .key {
            flex: auto;
            margin: 4px;
            width: 150px;
            height: 150px;
        }
    }

    .accept {
        margin-top: 20px;
        width: 275px;
        height: 140px;
        margin-left: 20px;
        font-size: 1.3em;
    }

    .cancel {
        margin-top: 20px;
        font-size: 1.3em;
        width: 275px;
        height: 140px;
    }
}