.lookup-table {
    .approved {
        background-color: rgba(0, 255, 0, 0.1);
    }
}

.quick-tip {
    font-size: 1.1rem;
    line-height: 1.2;
}

.raw-values {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    border-color: #bcbebf;

    label {
        font-weight: bold;
    }
}

.final-values {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: #f2f2f3;
    border-color: #bcbebf;

    .btn {
        width: 100%;
    }

    label {
        font-weight: bold;
    }
}

.manually-calculated {
    background-color: rgba(255, 0, 0, 0.1);
}